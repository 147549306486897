import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Login from './components/Login';
import Layout from './components/Layout';
import {CssBaseline} from '@mui/material';
import Earn from './pages/Earn';
import Ingredients from './pages/Ingredients';
import Recipes from './pages/Recipes';
import themeOptions from './styles/muiTheme'
import Dashboard from './pages/Dashboard';
import NotFound from './pages/NotFound';
import SlotMachine from './pages/SlotMachine';
import web3Modal from './utils/initWeb3Modal';
import {ethers} from 'ethers';
import {useAppDispatch} from './hooks';
import {accessToken, publicAddress} from './store/authSlice';
import './App.scss';
import Craft from './pages/Craft';
import Mint from './pages/Mint';
import Raffles from './pages/Raffles';
import Terms from './pages/Terms';
import LeaderBoard from './pages/LeaderBoard';

const darkTheme = createTheme(themeOptions);

function App() {
  const dispatch = useAppDispatch()
  const setAccount = (account: string | undefined) => dispatch(publicAddress(account as string))
  const setAccess = (token: string | null) => dispatch(accessToken(token))
  const onConnect = async() => {
    try {
      if (window.ethereum) {
        await web3Modal.connect()
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const accounts = async () => await provider.listAccounts();
        accounts().then(response => setAccount(response[0]))
      }
    }
    catch (error) {
      console.error(error)
      return;
    }
  }
  //TODO: refactor this
  setAccess(localStorage.getItem('Access'))
  window.addEventListener('storage', () => {
    setAccess(localStorage.getItem('Access'))
  });

  React.useEffect(() => {
    if (web3Modal.cachedProvider) onConnect()
  }, [web3Modal.cachedProvider])

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<Layout/>}>
          <Route path="login" element={<Login/>} />
          <Route path="game" element={<Login isGamePage={true} />} />
          <Route index element={<Dashboard/>} />
          <Route path="earn" element={<Navigate to="/earn/staking" replace />} />
          <Route path="earn/staking" element={<Earn/>} />
          <Route path="earn/lottery" element={<SlotMachine/>} />
          <Route path="shop" element={<Ingredients/>} />
          {/*<Route path="shop/ingredients" element={<Ingredients/>} />*/}
          <Route path="shop/recipes" element={<Recipes/>} />
          {/*<Route path="shop" element={<Navigate to="/shop/ingredients" replace />} />*/}
          <Route path="craft" element={<Craft />} />
          <Route path="leader-board" element={<LeaderBoard />} />
          <Route path="mint" element={<Mint />} />
          <Route path="raffles" element={<Raffles />} />
          <Route path="terms" element={<Terms />} />
          <Route path="*" element={<NotFound /> }/>
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
