import React from 'react';
import {Snackbar, Alert, Slide} from '@mui/material';
import './Login.scss';
import useLogin from '../../hooks/useLogin';
import {ReactComponent as Logo} from '../../assets/LogoNew.svg';
import {ReactComponent as Instagram} from '../../assets/Instagram.svg';
import {ReactComponent as Discord} from '../../assets/Discord.svg';
import {ReactComponent as X} from '../../assets/X.svg';

interface Props {
  isGamePage?: boolean
}

const Login: React.FC<Props> = ({ isGamePage = false }) => {
  const { account, disconnect, connectWallet, chainId, nonce, error, open } = useLogin();

  return (
    <div className="Login">
      <div className="frame">
        <div className="logo"><Logo /></div>
        <div className="button-container">
        {account ? (
          <button className="button" onClick={() => disconnect()}>DISCONNECT</button>
        ) : (
          <button className="button" onClick={() => connectWallet(isGamePage ? 'steam' : '/')}>ENTER THE LAB</button>
        )}
        </div>

        <Snackbar
          open={error.open}
          TransitionComponent={props => <Slide {...props} direction="left" />}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        >
          <Alert severity="error" sx={{ width: '100%' }}>
            {error.message}
          </Alert>
        </Snackbar>

        <Snackbar
          open={open}
          TransitionComponent={props => <Slide {...props} direction="left" />}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        >
          <Alert severity="success" sx={{ width: '100%' }}>
            Please, confirm nonce in your Metamask
          </Alert>
        </Snackbar>
      </div>
      <div className="footer">
        <div className="soc-nets">
          <a href="#">
            <Instagram />
          </a>
          <a href="#">
            <Discord />
          </a>
          <a href="#">
            <X />
          </a>
        </div>
        <div className="copyright">
          © 2021, NHance Studio
        </div>
      </div>
    </div>
  )
}

export default React.memo(Login)
