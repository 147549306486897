import React from 'react';
import cx from 'classnames';
import {Outlet, useMatch} from 'react-router-dom';
import Header from '../Header';
import SideBar from '../SideBar';
import {Box, Container, useMediaQuery, useTheme} from '@mui/material';
import './Layout.scss';

const Layout: React.FC = () => {
  const theme = useTheme();
  const matchXsWidth = useMediaQuery(theme.breakpoints.down('sm'));
  const matchLoginPath = useMatch({ path: '/login' });
  const matchGamePath = useMatch({ path: '/game' });
  const matchCraftPath = useMatch({ path: '/craft' });
  const matchMintPath = useMatch({ path: '/mint' });
  const matchLoginOrGamePath = matchLoginPath || matchGamePath;

  return (
    <Box
      sx={{ display: 'flex' }}
      className={cx(
        'Layout',
        matchXsWidth && '-mobile-view',
        matchLoginOrGamePath && '-login-page',
        (matchCraftPath || matchMintPath) && '-craft-page'
      )}
    >
      {!matchLoginOrGamePath && <SideBar />}
      <Box component="main" sx={{ flexGrow: 1, p: 0}}>
        {!matchLoginOrGamePath && <Header/>}
        <Container sx={{ p: 2}} className="container" maxWidth="xl">
          <Outlet/>
        </Container>
      </Box>
    </Box>
  )
}

export default React.memo(Layout)
