import React, {useState} from 'react'
import {
  Box,
  Button,
  Grid,
  Typography,
  Link,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody, CardHeader, Skeleton, Avatar, Pagination, TextField, Alert, Snackbar
} from '@mui/material';
import GridItem from '../../components/GridItem';
import {isLoading} from '../../store/globalSlice';
import {useAppDispatch, useAppSelector} from '../../hooks';
import TopPlayers from '../../components/TopPlayers';
import {NFT, NFTSmall} from '../../types/data-contracts'
import LastMonster from '../../components/LastMonster';
import Raffles from '../../components/Raffles';
import NewIngredients from '../../components/NewIngredients';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Modality from '../../components/Modality';
import {Player} from '../../components/TopPlayers/TopPlayers';
import {CountdownRendererFn} from 'react-countdown/dist/Countdown';
import {User} from '../../components/LastMonster/LastMonster'
import {useNavigate} from 'react-router-dom';
import {availableBalance} from '../../store/authSlice';
import './Dashboard.scss';
import {ReactComponent as Edit} from '../../assets/Edit.svg';
import {ReactComponent as Down} from '../../assets/AccordionDown.svg';
import cx from 'classnames';
import {log} from 'util';
import Countdown from 'react-countdown';
import moment from 'moment/moment';
import {ReactComponent as ClockAfternoon} from '../../assets/ClockAfternoon.svg';
import {ReactComponent as PauseCircle} from '../../assets/PauseCircle.svg';
import {ReactComponent as PlayCircle} from '../../assets/PlayCircle.svg';
import axios from 'axios';
import eventBus from '../../EventBus';
import {ReactComponent as Save} from '../../assets/OK.svg';
import {ReactComponent as Cancel} from '../../assets/Cancel.svg';

const today = new Date()
const tomorrow = new Date(today)
tomorrow.setDate(tomorrow.getDate() + 1)

const countDownRenderer: CountdownRendererFn = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // window.location.reload();
    return <span style={{marginLeft: '36px'}}> — </span>
  } else {
    return <span>{hours}h {minutes}min {seconds}s</span>
  }
}

const apiUrl = process.env.REACT_APP_BACKEND_URL

type Dashboard = {
  balance: string,
  top_players: Player[],
  last_monster_created: { user: User } & NFT,
} | undefined

const Dashboard: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const axiosPrivate = useAxiosPrivate()
  const setLoading = (loadingState: boolean) => dispatch(isLoading(loadingState))
  const loading = useAppSelector(state => state.global.loading)
  const userData = useAppSelector(state => state.user.data)

  console.log('userData', userData);
  const [data, setData] = React.useState<Dashboard>(undefined)
  const [login, setLogin] = React.useState<string>('');
  // const [openModal, setOpenModal] = React.useState<boolean>(false)
  const [openModal, setOpenModal] = React.useState<{open: boolean, nft: NFT | null}>({open: false, nft: null});
  const [openModalAvatar, setOpenModalAvatar] = React.useState<{open: boolean, nftSelected: undefined | null | number}>({open: false, nftSelected: null});
  const setAvailableBalance = (balance: string | undefined) => dispatch(availableBalance(balance))
  const balance = useAppSelector(state => state.user.availableBalance)
  const [activeAccordions, setActiveAccordions] = React.useState<number[]>([]);
  const setSelectedAvatar = (tokenId: number) => {
    setOpenModalAvatar({ ...openModalAvatar, nftSelected: tokenId });
  }

  const accordionClick = (index: number) => {
    const itemIndex = activeAccordions.indexOf(index);
    if (itemIndex === -1) {
      setActiveAccordions([...activeAccordions, index]);
    } else {
      const activeAccordionsClone = [...activeAccordions];
      activeAccordionsClone.splice(itemIndex, 1);
      setActiveAccordions(activeAccordionsClone);
    }
  }
  const [nftList, setNftList] = React.useState<NFT[] | undefined>(undefined);
  console.log('nftList', nftList);
  const [pagesCount, setPagesCount] = React.useState<number | undefined>(undefined);
  const controller = new AbortController();
  const handleOpen = (nft: NFT) => setOpenModal({open: true, nft: nft});
  const [isEditLogin, setIsEditLogin] = React.useState<boolean>(false);
  const handleCloseStaking = (nft: NFT | null) => {
    setOpenModal({open: false, nft: nft});
  }
  const handleCloseAvatar = () => {
    setOpenModalAvatar({open: false, nftSelected: null});
  };
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const accessToken = useAppSelector(state => state.user.accessToken);

  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    return `${hours}:${minutes}`;
  }
  const currentTime = getCurrentTime();

  const handleChangeAvatar = async () => {
    if (!openModalAvatar.nftSelected) return;

    axios.post(
      `${apiUrl}/api/v1/self/`,
      {
        avatar_id: openModalAvatar.nftSelected,
      },
      { headers: { 'Authorization': `Bearer ${accessToken}` }}
    ).then(() => {
      handleCloseAvatar();
      eventBus.dispatch('TRADE_PURCHASE');
      eventBus.dispatch('UPDATE_STATEMENT');
      setSnackbar({open: true, message: 'Avatar updated successfully!', type: 'success'});
    }).catch((error) => {
      console.error(error);
      setSnackbar({open: true, message: 'An error occurred', type: 'error'});
    });
  }

  const handleStake = async (nft: NFT | null) => {
    if (!nft) return;
    if (disabled) return;
    setDisabled(true);

    axios.post(
      `${apiUrl}/api/v1/nfts/stake`,
      {
        token_id: nft.token_id,
        contract_address: nft.contract_address,
        is_staked: !nft.is_staked
      },
      { headers: { 'Authorization': `Bearer ${accessToken}` }}
    ).then(() => {
      setNftList((prevState: any) => prevState.map(
          (stakedNft: { token_id: number; is_staked: boolean; }) => stakedNft?.token_id === nft.token_id ? {
              ...stakedNft,
              is_staked: !stakedNft.is_staked,
              next_reward_datetime: tomorrow
            }
            : stakedNft
        )
      )
      setOpenModal({open: false, nft})
      eventBus.dispatch('TRADE_PURCHASE');
      eventBus.dispatch('UPDATE_STATEMENT');
      setDisabled(false);
    }).catch((error) => {
      console.error(error);
      setDisabled(false);
    })
  }
  const [snackbar, setSnackbar] = React.useState<{open: boolean, message?: string, type?: 'success' | 'error'}>(
    {open: false, message: undefined, type: 'success'}
  );
  const getCurrentDate = () => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }
  const currentDate = getCurrentDate();

  const saveLogin = async () => {
    axios.post(
      `${apiUrl}/api/v1/self/`,
      {
        first_name: login,
      },
      { headers: { 'Authorization': `Bearer ${accessToken}` }}
    ).then(() => {
      setIsEditLogin(false);
      eventBus.dispatch('TRADE_PURCHASE');
      eventBus.dispatch('UPDATE_STATEMENT');
      setSnackbar({open: true, message: 'Login updated successfully!', type: 'success'});
    }).catch((error) => {
      console.error(error);
      setSnackbar({open: true, message: 'An error occurred', type: 'error'});
    })
  }

  const getEarnData = async (page: number) => {
    setLoading(true)
    try {
      const response = await axiosPrivate.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/nfts/`,
        {
          signal: controller.signal,
          params: { page: page, page_size: 999}
        }
      )
      setNftList(response.data.results)
      setPagesCount(Math.ceil(response.data.count / 999))
      setLoading(false)
      console.log('nftList', response.data.results);
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }
  const paginationNavigate = (page: number) => {
    setLoading(true)
    getEarnData(page).then(() => {
      setLoading(false)
      controller.abort()
    }).catch((error) => {
      console.log('!!! error:', error)
    })
  }

  const handleLoginChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLogin(event.target.value);
  }

  React.useEffect(() => {
    if (userData?.first_name) setLogin(userData.first_name);
    if (userData?.avatar?.token_id) setOpenModalAvatar({ ...openModalAvatar, nftSelected: userData.avatar.token_id });
  }, [userData]);

  React.useEffect(() => {
    paginationNavigate(1);
  }, []);

  // const handleClose = () => setOpenModal(false);
  React.useEffect(() => {
    setLoading(true)
    const controller = new AbortController();

    const getDashboardData = async () => {
      try {
        const response = await axiosPrivate.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/nfts/dashboard/`,
          {
            signal: controller.signal
          }
        )
        setData(response.data)
        setAvailableBalance(response.data.balance)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.error(error)
      }
    }
    getDashboardData()
    return () => {
      controller.abort()
    }
  }, [])
  return (
    <div className="Dashboard">
      <div className="Dashboard__top">
        <div className="Dashboard__top-left">
          <div className="welcome">
            Welcome,<br/>
            { isEditLogin ? <div className="welcome__edit">
              <TextField
                fullWidth
                value={login}
                placeholder="Your username here"
                InputLabelProps={{shrink: true}}
                required={true}
                onChange={handleLoginChange}
              />
              <Save className="welcome__edit-save" onClick={() => saveLogin()} />
              <Cancel className="welcome__edit-cancel" onClick={() => setIsEditLogin(false)} />
            </div> : <span>{ userData?.first_name || 'Stranger' } <Edit onClick={() => setIsEditLogin(true)} /></span> }
          </div>
          <div className="avatar" style={{backgroundImage: `url(${ userData?.avatar?.image_thumbnail_url || '/symbols/1.avif' })`}}>
            <Edit className="avatar__edit" onClick={() => setOpenModalAvatar({ open: true, nftSelected: userData?.avatar?.token_id })} />
          </div>
        </div>
        <div className="Dashboard__top-center">
          <div className="status">
            <div className="status__label">Status:</div>
            <div className="status__value">MEGALADON</div>
            <div className="status__numbers">
              { !loading && <>
                <span>{ nftList?.length }</span>
                <span>{ nftList?.filter(({ is_staked }) => is_staked).length }</span>
              </> }
            </div>
            <div className="status__info">Total/Staked</div>
          </div>
        </div>
        <div className="Dashboard__top-right">
          <div className="gems">
            <div className="gems__label">Your Gems:</div>
            <div className="gems__value">{ userData?.gems_balance } <span>💎</span></div>
            <div className="gems__button">
              <Button variant="contained" color="primary" onClick={() => navigate('/earn/staking')}>
                Transfer
              </Button>
            </div>
          </div>
          {/*<div className="time">*/}
          {/*  <div>Holding time: 2y 1m 22h</div>*/}
          {/*  <div>Staking time: 2y 1m 22h</div>*/}
          {/*</div>*/}
          <div className="timezone">
            <div className="timezone__label">{ currentDate }</div>
            <div className="timezone__value">{ currentTime }</div>
            {/*<div className="timezone__utc">*/}
            {/*  UTC*/}
            {/*  <Edit/>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
      <div className="Dashboard__bottom">
        <div className="Dashboard__bottom-left">
          <div className="Dashboard__title">GAMEHUB</div>
          <div className="banners">
            <div className="banner banner-raffle">
              <div className="banner__overlay" />
              <div className="banner__info">Raffle</div>
              <div className="banner__button">
                <Button variant="contained" color="primary" onClick={() => navigate('/raffles')}>
                  Try your Luck
                </Button>
              </div>
            </div>
            <div className="banner">
              <img className="banner__logo" src={require('../../assets/banner-logo.png')} alt="Sharded World"/>
              <div className="banner__info">
                <span>120D</span>
                Season1
              </div>
              <div className="banner__button">
                <Button variant="contained" color="primary" onClick={() => navigate('/leader-board')}>
                  Try your Skill
                </Button>
              </div>
            </div>
          </div>
          <div className="accordion">
            <div className="accordion__title">ABOUT THE CLUB</div>
            <div className={cx('accordion__item', activeAccordions.includes(0) && 'accordion__item--active')}>
              <div className="accordion__item-title" onClick={() => accordionClick(0)}>
                <Down/>
                Monster Ape Club - what the hell is this place?
              </div>
              <div className="accordion__item-body">
                <div className="accordion__item-body-text">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab delectus
                  dignissimos distinctio doloribus expedita itaque iure laboriosam laudantium maiores molestiae mollitia numquam,
                  officiis praesentium soluta sunt tempora unde vero voluptatibus!
                </div>
              </div>
            </div>
            <div className={cx('accordion__item', activeAccordions.includes(1) && 'accordion__item--active')}>
              <div className="accordion__item-title" onClick={() => accordionClick(1)}>
                <Down/>
                Why should be here?
              </div>
              <div className="accordion__item-body">
                <div className="accordion__item-body-text">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab delectus
                  dignissimos distinctio doloribus expedita itaque iure laboriosam laudantium maiores molestiae mollitia numquam,
                  officiis praesentium soluta sunt tempora unde vero voluptatibus!
                </div>
              </div>
            </div>
            <div className={cx('accordion__item', activeAccordions.includes(2) && 'accordion__item--active')}>
              <div className="accordion__item-title" onClick={() => accordionClick(2)}>
                <Down/>
                What is the club roadmap in cryptospace?
              </div>
              <div className="accordion__item-body">
                <div className="accordion__item-body-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab delectus
                  dignissimos distinctio doloribus expedita itaque iure laboriosam laudantium maiores molestiae mollitia numquam,
                  officiis praesentium soluta sunt tempora unde vero voluptatibus!
                </div>
              </div>
            </div>
          </div>
          <div className="accordion">
            <div className="accordion__title">FAQ</div>
            <div className={cx('accordion__item', activeAccordions.includes(3) && 'accordion__item--active')}>
              <div className="accordion__item-title" onClick={() => accordionClick(3)}>
                <Down/>
                How do I get the game?
              </div>
              <div className="accordion__item-body">
                <div className="accordion__item-body-text">You can purchase the game in two ways through the game store or for
                  tokens on
                  the site, after the purchase you will have the opportunity to participate in the event.
                </div>
              </div>
            </div>
            <div className={cx('accordion__item', activeAccordions.includes(4) && 'accordion__item--active')}>
              <div className="accordion__item-title" onClick={() => accordionClick(4)}>
                <Down/>
                Another question?
              </div>
              <div className="accordion__item-body">
                <div className="accordion__item-body-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab delectus
                  dignissimos distinctio doloribus expedita itaque iure laboriosam laudantium maiores molestiae mollitia numquam,
                  officiis praesentium soluta sunt tempora unde vero voluptatibus!
                </div>
              </div>
            </div>
            <div className={cx('accordion__item', activeAccordions.includes(5) && 'accordion__item--active')}>
              <div className="accordion__item-title" onClick={() => accordionClick(5)}>
                <Down/>
                Another question?
              </div>
              <div className="accordion__item-body">
                <div className="accordion__item-body-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab delectus
                  dignissimos distinctio doloribus expedita itaque iure laboriosam laudantium maiores molestiae mollitia numquam,
                  officiis praesentium soluta sunt tempora unde vero voluptatibus!
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Dashboard__bottom-right">
          <div className="Dashboard__title">STAKING</div>
          <div className="staking-card">
            {nftList && nftList.length > 0 || loading ? (
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{minWidth: 200}}>
                        <Typography variant="body2">NFT List</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">Stake/Unstake</Typography>
                      </TableCell>
                      <TableCell sx={{minWidth: 140}}>
                        <Typography variant="body2">
                          <ClockAfternoon style={{verticalAlign: 'middle', margin: '-2px 4px 0 0'}}/>
                          Time Left
                        </Typography>
                      </TableCell>
                      <TableCell sx={{minWidth: 100}}>
                        <Typography variant="body2">Reward</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading
                      ? (
                        <TableRow>
                          <TableCell>
                            <CardHeader
                              avatar={
                                <Skeleton animation="wave" width="40px" height="40px"/>
                              }
                              title={<Skeleton animation="wave" height="20px" width="100px" sx={{mb: 1}}/>}
                              subheader={<Skeleton animation="wave" height="10px" width="60px" sx={{mb: 0.5}}/>}
                            />
                          </TableCell>
                          <TableCell>
                            <Skeleton animation="wave" width="122px" height="40px" sx={{borderRadius: '8px'}}/>
                          </TableCell>
                          <TableCell>
                            <Skeleton animation="wave" height="20px" width="100px"/>
                          </TableCell>
                          <TableCell>
                            <Skeleton animation="wave" height="20px" width="100px"/>
                          </TableCell>
                        </TableRow>
                      ) : nftList && nftList.length && nftList.map((token: NFT) => (
                      <TableRow key={token?.token_id}>
                        <TableCell>
                          <CardHeader
                            avatar={
                              <Avatar
                                variant="rounded"
                                src={`${token?.image_thumbnail_url}`}
                              >{token?.name}</Avatar>
                            }
                            title={token?.name}
                            subheader={<small className="secondary">{token?.name}</small> }
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            onClick={() => handleOpen(token)}
                            size="medium"
                            variant="outlined"
                            color={token?.is_staked ? 'error' : 'success'}
                            startIcon={token?.is_staked ? <PlayCircle/> : <PauseCircle/>}
                          >
                            {token?.is_staked ? "Unstake" : "Stake"}
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" className="secondary">
                            {token?.is_staked
                              ? <Countdown
                                renderer={countDownRenderer}
                                date={moment.utc(token?.next_reward_datetime).local().valueOf()}
                              />
                              : <span style={{marginLeft: '36px'}}> — </span>
                            }
                          </Typography>
                        </TableCell>
                        <TableCell dangerouslySetInnerHTML={
                          // @ts-ignore
                          {__html: token.reward
                              ?.replace('24h', 'day')
                              ?.replace(
                                'gems',
                                '<span style="font-size: 12px; position: relative; top: -2px;">💎</span>'
                              )}
                        } />
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography align="center" variant="h6" sx={{mt: 5}}>
                No Monster Ape Club NFTs in your wallet.
                <br/>
                You can purchase some &nbsp;
                <Link
                  href="https://opensea.io/collection/monsterapeclub-original"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  here
                </Link>
              </Typography>
            )}
          </div>
        </div>
      </div>
      <Modality
        open={openModalAvatar.open}
        handleClose={() => handleCloseAvatar()}
        confirmText="Save"
        handleConfirm={() => handleChangeAvatar()}
      >
        {nftList && nftList.length > 0 ? <div className="avatar-modal">
          <Typography variant="h6" sx={{ my: 2 }}>
            <b>Choose your avatar</b>
          </Typography>
            <div className="avatar-modal__list">
          {nftList.map((nft) => (
            <div className={cx('avatar-modal__item', nft?.token_id === openModalAvatar.nftSelected && 'avatar-modal__item--active')} key={nft?.token_id}>
              { nft.image_thumbnail_url && <img width="100px" src={nft.image_thumbnail_url} alt={nft.name} onClick={() => setSelectedAvatar(nft.token_id)} /> }
            </div>
          ))}
            </div>
        </div> : <div>You have no NFT's</div> }
      </Modality>

      <Modality
        open={openModal.open}
        handleClose={() => handleCloseStaking(openModal.nft)}
        confirmText={openModal.nft?.is_staked ? 'I\'m Sure' : 'Stake it!'}
        handleConfirm={() => handleStake(openModal.nft)}
      >
        {openModal.nft?.is_staked ? (
          <>
            <Typography variant="h6" sx={{ my: 2 }}>
              <b>Are you sure you want to unstake this NFT?</b>
            </Typography>

            <Typography variant="body2" sx={{ my: 2 }}>
              You
              { openModal.nft?.reward?.split(' ')?.[0] ? ` will lose upcoming reward of ${openModal.nft?.reward?.split(' ')?.[0]} gems` : '' }
              { openModal.nft?.reward?.split(' ')?.[0] && openModal.nft?.gems_loss_probability_percent && openModal.nft?.gems_loss_probability ? ' and' : '' }
              {openModal.nft?.gems_loss_probability_percent && openModal.nft?.gems_loss_probability ?
                ` have a ${openModal.nft?.gems_loss_probability_percent}% chance of 
                losing ${openModal.nft?.gems_loss_probability} gems that you already have` : ''}!
              {/*In just {moment.utc(openModal.nft.next_reward_datetime).local().endOf('days').fromNow()} you will be rewarded with 10 Gems!*/}
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h6" sx={{ my: 2 }}>
              <b>3 easy steps to earn Gems</b>
            </Typography>
            <Typography variant="body2" sx={{ my: 2 }} component="p">
              1. DO NOT put your asset on sell
            </Typography>
            <Typography variant="body2" sx={{ my: 2 }} component="p">
              2. DO NOT transfer your asset
            </Typography>
            <Typography variant="body2" sx={{ my: 2 }} component="p">
              3. Earn 10 gems every 24 hours as a reward
            </Typography>
          </>
        )}
      </Modality>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={2000}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.type} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default React.memo(Dashboard)
